<template>
	<standard-container title="Škoda ŠuperCard" description="" icon="mdi-home" nocard>
		<v-row>
			<v-col>
				<v-card :color="darkTheme ? 'rgba(0,0,0,0.75)': 'rgba(255,255,255,0.75)'" elevation="0" class="mt-6 ">
					<v-card-text class="testoCard">
						<img v-if="darkTheme" src="../assets/Skoda_Wordmark_RGB_Electric_Green.png" />
						<img v-else src="../assets/Skoda_Wordmark_RGB_Emerald_Green.png" />
						<h3 class="text--primary">
							Benvenuto nel portale Škoda ŠuperCard: un mondo di vantaggi riservati a tutti i clienti Škoda che hanno scelto di rivolgersi alla qualità dei centri Autorizzati Škoda Service.
						</h3>
						<p>
							Ogni intervento di manutenzione effettuato presso i centri Autorizzati Škoda Service, escluse le garanzie*, permetterà al Cliente Škoda di ottenere punti nella Škoda ŠuperCard ed ottenere, a seconda dei punti accumulati, sconto dal 5% al 17% su Ricambi e Accessori Originali Škoda sostituiti presso l'Organizzato Škoda.
							<br>
							Da questa interfaccia web puoi attivare le Škoda ŠuperCard, registrando i dati del Cliente e stampare il regolamento in duplice copia. Trattenere una copia del regolamento e una copia dell'informativa sul trattamento dei dati personali firmata dal cliente, consegnare la seconda al cliente.
							<br>
							Inoltre, si possono visualizzare il saldo punti e verificare le promozioni attive.
						</p>
						<p>
							Il nostro principale obiettivo è da sempre quello di mettere a vostra disposizione uno strumento di lavoro efficace, ed è per questo che ogni tuo suggerimento sarà gradito; puoi contattarci inviando una mail a 
							<a href="mailto:skodasupercard@servicedirect.it">skodasupercard@servicedirect.it</a>
						</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="setup.EnableDashboardTodoSystem">
			<v-col md="6">
				<v-card :color="darkTheme ? 'rgba(0,0,0,0.75)': 'rgba(255,255,255,0.75)'" elevation="0">
					<v-card-title>Personal todo</v-card-title>
					<v-card-text>
						<todo-list></todo-list>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import TodoList from '@/components/todo-list'
export default {
	name: 'Home',
	components: { StandardContainer, TodoList },
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		darkTheme() { return this.$vuetify.theme.dark },
	},
	mounted() {
	},
	methods: {

	},
}
</script>



<style scoped lang="less">
	.testoCard {
		padding: 30px;
		font-size: 20px;
		line-height: 30px;
		h3 {
			line-height: 35px;
		}
		h3, p {
			margin-bottom: 50px;
		}
		img {
			margin-top: 30px;
			margin-bottom: 50px;
			width: 100%;
			height: auto;
			max-width: 600px;
		}
	}
</style>
